<template>
  <div class="demo-app" :class="mode ? 'card__tableday' : 'card__tablenight'">
    <div class="demo-app-sidebar">
      <div class="evet_forms">
        <el-form ref="form" status-icon :model="newEvent">
          <div class="form-group">
            <label for="title">{{ $t("message.nameGiven") }}</label>
            <el-input
              :placeholder="$t('message.nameGiven')"
              v-model="newEvent.title"
              clearable
            >
            </el-input>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <label for="title">{{ $t("message.from_data") }}</label>
              <el-date-picker
                v-model="newEvent.start"
                :class="mode ? 'input__day' : 'input__night'"
                size="small"
                type="date"
                value="yyyy MMMM dd"
                value-format="yyyy-MM-dd"
                class="mr-3"
                :placeholder="$t('message.from_data')"
              >
              </el-date-picker>
            </div>
            <div class="form-group col-6">
              <label for="title">{{ $t("message.to_data") }}</label>
              <el-date-picker
                v-model="newEvent.end"
                :size="'small'"
                type="date"
                value="yyyy MMMM dd"
                value-format="yyyy-MM-dd"
                :class="mode ? 'input__day' : 'input__night'"
                :placeholder="$t('message.to_data')"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="app-form__group mb-4">
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ $t("message.type") }}
            </span>
            <el-form-item prop="type">
              <el-select
                class="d-block"
                v-model="newEvent.type"
                :placeholder="$t('message.type')"
                :class="mode ? 'input__day' : 'input__night'"
              >
                <el-option
                  v-for="item in calendar_types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <select-hidden-company
            :size="'medium'"
            :id="newEvent.company_id"
            v-model="newEvent.company_id"
            @dataLength="companyLength"
            :multiple="true"
          ></select-hidden-company>

          <div v-if="addingMode">
            <el-button type="primary" @click="addNewEvent">{{
              $t("message.save")
            }}</el-button>
          </div>
          <div v-else>
            <el-button type="primary" @click="updateEvent">{{
              $t("message.save")
            }}</el-button>
            <el-button type="danger" @click="deleteEvent">{{
              $t("message.delete")
            }}</el-button>
            <el-button type="primary" @click="cancelEvent">{{
              $t("message.cancel")
            }}</el-button>
          </div>
        </el-form>
      </div>
      <div class="demo-app-sidebar-section">
        <h2>All Events ({{ currentEvents.length }})</h2>
        <ul>
          <li v-for="event in calendarOptions.events" :key="event.id">
            <b>{{ event.start }}</b> -
            <b>{{ event.end }}</b>
            <i> {{ event.title }}</i>
          </li>
        </ul>
      </div>
    </div>
    <div class="demo-app-main">
      <FullCalendar
        :class="mode ? 'calendar__day' : 'calendar__night'"
        @eventClick="showEvent"
        ref="fullCalendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
          <i>{{ arg.event.type }}</i>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import calendarTypes from "./calendar_types";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import selectHiddenCompany from "@/components/filters/inventory/select-or-hidden-company";
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  mixins: [calendarTypes],
  components: {
    FullCalendar, // make the <FullCalendar> tag available,
    selectHiddenCompany
  },
  data: function() {
    return {
      calendarPlugins: [dayGridPlugin, interactionPlugin],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: [],
        editable: true,
        selectable: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        firstDay: ["1"]
      },
      newEvent: {
        title: "",
        start: "",
        end: "",
        type: "",
        company_id: ""
      },

      currentEvents: [],
      events: "",
      addingMode: true,
      indexToUpdate: ""
    };
  },
  created() {
    this.getEvents();
    this.calendarOptions.initialEvents;
    console.log(this.calendarOptions);
  },
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      console.log(selectInfo);
      this.newEvent.title = "day_off";
      this.newEvent.start = selectInfo.startStr;
      this.newEvent.end = selectInfo.endStr;
      this.newEvent.type = selectInfo.type;
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo);
      this.newEvent.title = clickInfo.event.title;
      this.newEvent.start = clickInfo.event.startStr;
      this.newEvent.end = clickInfo.event.endStr;
      this.newEvent.type = this.calendarOptions.events.find(
        event => event.id === +clickInfo.event.id
      )["type"];
      this.indexToUpdate = clickInfo.event.id;
      this.addingMode = false;
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    addNewEvent() {
      axios
        .post(process.env.VUE_APP_URL + "/calendar/store", {
          ...this.newEvent
        })
        .then(data => {
          this.getEvents();
          this.resetForm();
        })
        .catch(err => console.log(err));
    },
    deleteEvent() {
      axios
        .delete(
          process.env.VUE_APP_URL + "/calendar/destroy/" + this.indexToUpdate
        )
        .then(data => {
          this.resetForm();
          this.getEvents();
          this.addingMode = !this.addingMode;
        })
        .catch(err => console.log(err));
    },
    getEvents() {
      axios
        .get(process.env.VUE_APP_URL + "/calendar/index")
        .then(resp => (this.calendarOptions.events = resp.data.data))
        .catch(err => console.log(err));
    },
    updateEvent() {
      axios
        .put(
          process.env.VUE_APP_URL + "/calendar/update/" + this.indexToUpdate,
          {
            ...this.newEvent
          }
        )
        .then(data => {
          this.resetForm();
          this.getEvents();
          this.addingMode = !this.addingMode;
        })
        .catch(err => console.log(err));
    },
    resetForm() {
      Object.keys(this.newEvent).forEach(key => {
        return (this.newEvent[key] = "");
      });
    },
    cancelEvent() {
      this.addingMode = !this.addingMode;
      this.resetForm();
    },
    showEvent(arg) {
      this.showEvent = false;
      const { id, title, start, end, type } = this.calendarOptions.events.find(
        event => event.id === +arg.event.id
      );
      this.indexToUpdate = arg;

      this.newEvent = {
        title: title,
        start: start,
        end: end,
        type: type
      };
    },
    companyLength(val) {
      this.newEvent.company_id = val;
    }
  },
  watch: {
    indexToUpdate() {
      return this.indexToUpdate;
    }
  }
};
</script>

<style lang="scss">
.evet_forms {
  padding: 20px;
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    gap: 15px;
    .form-group {
      box-sizing: border-box;
      .el-date-editor.el-input {
        width: auto !important;
      }
    }
  }
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 450px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
}
.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
</style>
