export default {    
    data() {
        return {
          calendar_types: [
                { label: "Праздничный день", value: "holiday" },
                { label: "Напоминание", value: "plan" },
            ],
        }
    }
  }
